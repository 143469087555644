// components
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/header-bl-design.jpg";
import Globe from "../images/icon/countriesIcon.svg";
import time from "../images/icon/time.svg";
import CheckIcon from "../images/icon/check.svg";
import Facts from "../images/confi/location.svg";
import Foursource from "../images/confi/foursource.svg";
import ApparelManufacturer from "../images/confi/apparelManufacturer.svg";
import Banner from "../images/confi/banner.svg";
import bettinga from "../images/bl-design/head-shot-bettinga-1x1.jpg";


export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("bl-design.topTitle"),
        colorClass: "colorLight",
      },
      /* subtitle: {
        text: t("bl-design.subTitle"),
        colorClass: "colorLight",
      },
      cta: {
        text: t("bl-design.topCta"),
        colorClass: "colorLight",
        url: t("bl-design.ctaUrl"),
        type: "mainColor",
      }, */
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("bl-design.imgAlt"),
      },
    },
    topIcons: [
      {
        title: {
          text: t("bl-design.newMarketsValue"),
          textAfter: "%",
          isNum: true,
        },
        subtitle: {
          text: t("bl-design.newMarketsSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: time,
          alt: t("bl-design.newMarketsAlt"),
        },
      },
      {
        title: {
          text: t("bl-design.roiValue"),
          isNum: true,
        },
        subtitle: {
          text: t("bl-design.roiSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Globe,
          alt: t("bl-design.roiAlt"),
        },
      },
      /*{
        title: {
          text: t("bl-design.recruitingValue"),
          textBefore: "+",
          isNum: true,
        },
        subtitle: {
          text: t("bl-design.recruitingSubtitle"),
          colorClass: "colorDark",
        },
        icon: {
          src: Handshake,
          alt: t("bl-design.recruitingAlt"),
        },
      },*/
    ],
    sideIcons1: [
      {
        title: {
          text: t("bl-design.locationTitle"),
        },
        subtitle: {
          text: t("bl-design.locationSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Facts,
          alt: t("bl-design.locationAlt"),
        },
      },
      /*{
        title: {
          text: t("bl-design.productTitle"),
        },
        subtitle: {
          text: t("bl-design.productSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Apparel,
          alt: t("bl-design.productAlt"),
        },
      },*/
      {
        title: {
          text: t("bl-design.memberTitle"),
        },
        subtitle: {
          text: t("bl-design.memberSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: ApparelManufacturer,
          alt: t("bl-design.memberAlt"),
        },
      },
      /*{
        title: {
          text: t("bl-design.membershipTitle"),
        },
        subtitle: {
          text: t("bl-design.membershipSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Profile,
          alt: t("bl-design.membershipAlt"),
        },
      },
      {
        title: {
          text: t("bl-design.sizeTitle"),
        },
        subtitle: {
          text: t("bl-design.sizeSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Size,
          alt: t("bl-design.sizeAlt"),
        },
      },*/
      {
        title: {
          text: t("bl-design.sinceTitle"),
        },
        subtitle: {
          text: t("bl-design.sinceSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Foursource,
          alt: t("bl-design.sinceAlt"),
        },
      },
    ],
    sideIcons2: [
      {
        title: {
          text: t("bl-design.challengesSquareTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("bl-design.challengesSquareSubtitle"),
          colorClass: "colorDark",
          textSize: "font17  pt-10"
        },
        icon: {
          src: '',
          alt: t("bl-design.newMarketsAlt"),
        },
      },
    ],
    sideIcons3: [
      {
        title: {
          text: t("bl-design.favoriteToolsTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("bl-design.favoriteToolsSubtitle"),
          colorClass: "colorDark",
          textSize: " font17 pt-20"
        },
        icon: {
          src: '',
          alt: t("bl-design.favoriteToolsAlt"),
        },
        banner: true,
        bannerSrc: Banner,
        bannerAlt: "Banner"
      },
    ],
    bettina: [
      {
        title: {
          text: t("bl-design.bettinaName"),
        },
        subtitle: {
          text: t("bl-design.bettinaTitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: bettinga,
          alt: t("bl-design.newMarketsAlt"),
        },
      },
    ],
    aboutInfo: {
      title: {
        text: t("bl-design.aboutTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("bl-design.aboutText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    challengesInfo: {
      title: {
        text: t("bl-design.challengesTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("bl-design.challengesText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    findingInfo: {
      title: {
        text: t("bl-design.findingTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("bl-design.findingText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    resultsInfo: {
      title: {
        text: t("bl-design.resultsTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("bl-design.resultsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    bullet: {
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [
          t("bl-design.bullet1"),
          t("bl-design.bullet2"),
          t("bl-design.bullet3"),
        ],
        colorClass: "colorDark",
      },
      customPBText: true,
    },
    bottomText:  [
      {
        text: t("bl-design.bulletBottomText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    cta: {
      text: t("bl-design.cta1"),
      url: "https://foursource.com/apparel-manufacturers/",
      type: "whiteColor",
    },
    cta2: {
      text: t("bl-design.cta2"),
      url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    squareText: t("bl-design.challengesSquareSubtitle2"),
    favoriteToolsExtra: t("bl-design.favoriteToolsExtra"),
    profileText: t("bl-design.profileText"),
    testimonyName: t("bl-design.bettinaName"),
    testimonyTitle: t("bl-design.bettinaTitle"),
    anchorText: t("bl-design.anchorText")
  };
};
