export const contactTexts = (t) => {
  return {
    pressInquires: {
      title: {
        text: t("about-us.pressTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      email:t("about-us.prEmail"),
      emailLink:t("about-us.prEmailLink"),
      separator: true,
    },
    ourContact: {
      title: {
        text: t("about-us.prContact"),
        colorClass: "colorDark",
        extraClass: "font40",
      },

      email:t("about-us.prEmail"),
      emailLink:t("about-us.prEmailLink"),
      separator: true,
    },
    team: {
      title: {
        text: t("about-us.teamTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      email:t("about-us.teamEmail"),
      emailLink:t("about-us.teamEmailLink"),
      separator: true,
    },
  };
};
