export const valuesTexts = (t) => {
  return {
    ourValues: {
      title: {
        text: t("about-us.ourValuesText"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      separator: true,
    },
    ourValuesContent: [
      {
        title: {
          text: t("about-us.customersTitle"),
          colorClass: "colorDark",
        },
        text: {
          text: t("about-us.customersText"),
          colorClass: "colorDark",
          textType: "paragraph",
        },
        separator: false,
      },
      {
        title: {
          text: t("about-us.mistakesTitle"),
          colorClass: "colorDark",
        },
        text: {
          text: t("about-us.mistakesText"),
          colorClass: "colorDark",
          textType: "paragraph",
        },
        separator: false,
      },
      {
        title: {
          text: t("about-us.empowerTitle"),
          colorClass: "colorDark",
        },
        text: {
          text: t("about-us.empowerText"),
          colorClass: "colorDark",
          textType: "paragraph",
        },
        separator: false,
      },
      {
        title: {
          text: t("about-us.colabTitle"),
          colorClass: "colorDark",
        },
        text: {
          text: t("about-us.colabText"),
          colorClass: "colorDark",
          textType: "paragraph",
        },
        separator: false,
      },
      {
        title: {
          text: t("about-us.helpTitle"),
          colorClass: "colorDark",
        },
        text: {
          text: t("about-us.helpText"),
          colorClass: "colorDark",
          textType: "paragraph",
        },
        separator: false,
      },
      {
        title: {
          text: t("about-us.funTitle"),
          colorClass: "colorDark",
        },
        text: {
          text: t("about-us.funText"),
          colorClass: "colorDark",
          textType: "paragraph",
        },
        separator: false,
      },
    ],
  };
};
