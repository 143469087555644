import * as React from "react";
import CountUp from "react-countup";
import Parser from "html-react-parser";
class SmallIconText extends React.Component {
  render() {
    const { info } = this.props;

    return (
      <div className="iconTextContent pt-md-0 pt-sm-4 pt-3 d-flex justify-content-center flex-column align-items-center mb-10">
        {info.banner && (
          <div>
            {" "}
            <img
              className="height-50px position-absolute right15px top0"
              src={info.bannerSrc}
              alt={info.bannerAlt}
            ></img>{" "}
          </div>
        )}
        {info.icon.src && (
          <img
            /*className="height-50px"*/
            src={info.icon.src}
            alt={info.icon.alt}
          />
        )}
        <div
          className={
            info.title.textSize +
            " fontWeightBold font17 letterSpacing2 text-center"
          }
        >
          {info.title.textBefore}
          {info.title.isNum ? (
            <CountUp start={0} end={parseInt(info.title.text)} duration={3} />
          ) : (
            Parser(info.title.text)
          )}
          {info.title.textAfter}
        </div>
        <p
          className={
            info.subtitle.textSize +
            " font11 px-3 text-center " +
            info.subtitle.colorClass
          }
        >
          {Parser(info.subtitle.text)}
        </p>
      </div>
    );
  }
}

export default SmallIconText;
